import { format, parseISO } from "date-fns";
import type {
  Integration,
  IntegrationName,
  LocalFilesIntegration,
} from "carbon-connect";
import upperCase from "lodash/upperCase";
import type { DocumentStatus, DocumentType, RagDocument } from "./types";

export const getBadgeKind = (value: DocumentStatus) => {
  switch (value) {
    case "READY":
      return "success";
    case "SYNCING":
    case "UNKNOWN":
      return "warning";
    case "SYNC_ERROR":
      return "error";
  }
};

export const getIntegrationIcon = (value: DocumentType | IntegrationName) => {
  switch (value) {
    case "TSV":
      return "tsv-file";
    case "CSV":
      return "csv-file";
    case "RTF":
      return "rtf-file";
    case "DOC":
    case "DOCX":
      return "doc-file";
    case "PPT":
    case "PPTX":
      return "ppt-file";
    case "XLSM":
    case "XLSX":
      return "xls-file";
    case "JSON":
      return "json-file";
    case "PDF":
      return "pdf-file";
    case "TEXT":
    case "TXT":
      return "txt-file";
    case "MD":
      return "md-file";
    case "NOTION":
      return "notion";
    case "WEB_SCRAPER":
      return "global-line";
    case "ZENDESK":
      return "zendesk";
    case "GOOGLE_DRIVE":
      return "google-drive";
    case "SALESFORCE":
      return "salesforce";
    default:
      return "file-line";
  }
};

export const getIntegrationTitle = (value?: IntegrationName | string) => {
  switch (value) {
    case "LOCAL_FILES":
      return "Local files";
    case "NOTION":
      return "Notion";
    case "WEB_SCRAPER":
      return "Web scraper";
    case "ZENDESK":
      return "Zendesk";
    case "GOOGLE_DRIVE":
      return "Google drive";
    case "SALESFORCE":
      return "Salesforce";
    case "INTERCOM":
      return "Intercom";
    case "FRESHDESK":
      return "Freshdesk";
    case "CONFLUENCE":
      return "Confluence";
    case "DROPBOX":
      return "Dropbox";
    case "BOX":
      return "Box";
    case "TXT":
      return "TXT";
    case "PDF":
      return "PDF";
    case "MD":
      return "MD";
    case "RTF":
      return "RTF";
    case "DOCX":
      return "DOCX";
    default:
      return value;
  }
};

export const mapDocumentsToTableData = (documents?: RagDocument[]) => {
  if (!documents) return [];

  return documents.map((result) => {
    return {
      key: result.ragId,
      col1: result.syncStatus,
      col2: result.integrationType,
      col3: {
        name: result.name,
        url: result.isWebSource ? result.sourceUrl : result.documentUrl,
      },
      col4: result.integrationType,
      col5: result.size,
      col6: result.updatedAt,
      col7: result.ragId,
      col8: result.ragId,
    };
  });
};

export const isoStringToLocalDate = (isoString: string) => {
  return format(parseISO(isoString), "dd.MM.yy HH:mm:ss");
};

export const updateIntegrationProperty = (
  integrations: Integration[],
  id: string,
  property: string,
  value: number,
): Integration[] => {
  return integrations.map((integration) => {
    if (integration.id === id) {
      return {
        ...integration,
        [property]: value,
      };
    }

    return integration;
  });
};

export const updateFileIntegrationProperty = (
  integrations: Integration[],
  extension: string,
  property: string,
  value: number | boolean,
): Integration[] => {
  return integrations.map((integration) => {
    if (integration.id === "LOCAL_FILES") {
      const updatedFileTypes = (
        integration as LocalFilesIntegration
      ).allowedFileTypes?.map((fileType) => {
        if (upperCase(fileType.extension) === extension) {
          return {
            ...fileType,
            [property]: value,
          };
        }

        return fileType;
      });

      return {
        ...integration,
        allowedFileTypes: updatedFileTypes,
      };
    }

    return integration;
  });
};

/**
 * This function merges two sets of integrations: datasourceIntegrations and carbonIntegrations.
 * It ensures that configurations from datasourceIntegrations (user-specific settings) are applied
 * to corresponding integrations in carbonIntegrations, where applicable.
 *
 * - If either datasourceIntegrations or carbonIntegrations is missing, it returns an empty array.
 * - It iterates over carbonIntegrations and attempts to find a matching integration in datasourceIntegrations
 *   (matching by id).
 * - If a match is found:
 *   - For the "LOCAL_FILES" integration, it updates the allowed file types by preserving properties such as chunkSize,
 *     overlapSize, and additional settings for PDFs (useOcr, setPageAsBoundary).
 *   - For other integrations, it simply transfers chunkSize and overlapSize from datasourceIntegrations
 *     to carbonIntegrations.
 * - If no match is found, it keeps the original carbonIntegration unchanged.
 *
 * @param datasourceIntegrations - The list of user-specific integrations with additional configurations.
 * @param carbonIntegrations - The list of base integrations provided by Carbon.
 * @returns A new array of Integration objects with updated configurations.
 */
export const carbonIntegrationsAdapter = (
  datasourceIntegrations?: Integration[],
  carbonIntegrations?: Integration[],
): Integration[] => {
  if (!datasourceIntegrations || !carbonIntegrations) return [];

  return carbonIntegrations.map((carbonIntegration) => {
    const datasourceIntegration = datasourceIntegrations.find(
      (userInt) => userInt.id === carbonIntegration.id,
    );

    if (datasourceIntegration) {
      if (carbonIntegration.id === "LOCAL_FILES") {
        const updatedFileTypes = (
          carbonIntegration as LocalFilesIntegration
        ).allowedFileTypes?.map((fileType) => {
          const userFileType = (
            datasourceIntegration as LocalFilesIntegration
          ).allowedFileTypes?.find(
            (userFile) =>
              upperCase(userFile.extension) === upperCase(fileType.extension),
          );

          if (userFileType) {
            return {
              ...fileType,
              chunkSize: userFileType.chunkSize,
              overlapSize: userFileType.overlapSize,
              ...(upperCase(fileType.extension) === "PDF" && {
                useOcr: userFileType.useOcr,
                setPageAsBoundary: userFileType.setPageAsBoundary,
              }),
            };
          }

          return fileType;
        });

        return {
          ...carbonIntegration,
          chunkSize: datasourceIntegration.chunkSize,
          overlapSize: datasourceIntegration.overlapSize,
          allowedFileTypes: updatedFileTypes,
        };
      } else {
        return {
          ...carbonIntegration,
          chunkSize: datasourceIntegration.chunkSize,
          overlapSize: datasourceIntegration.overlapSize,
        };
      }
    }

    return carbonIntegration;
  });
};
